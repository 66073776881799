import axios from "axios";
import QS from "qs";
import { ElMessage, progressProps } from "element-plus";
import router from "@/router";
 
// 自动切换环境
if (process.env.NODE_ENV === "development") {
  // 本地
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
} else if (process.env.NODE_ENV === "debug") {
  // 报错
  axios.defaults.baseURL = "/";
  // axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
} else if (process.env.NODE_ENV === "production") {
  // 打包后
  axios.defaults.baseURL = window.configObj.baseURL;
}

// 设置超时时间
axios.defaults.timeout = 1000 * 60 * 30;
// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

 


export function request({ method, url, params,config }) {
 
  if(params.siteId==undefined){
    params.siteId = 1 // 1兰麦考前 2行业培训 3官网
  }
  if(params.code==undefined){
    params.code=''  //权限代码
  }

  axios.defaults.headers.get["siteId"] =  params.siteId;
  axios.defaults.headers.post["siteId"] =  params.siteId;
  axios.defaults.headers.get["code"] =  params.code;    
  axios.defaults.headers.post["code"] =  params.code;

  if (method === "get") {
    return sendGet(url, params);
  } else if (method === "post") {
    return sendPost(url, params);
  } else if (method === "form") {
    return sendForm(url, params);
  } else if (method === "file") {
    return sendFile(url, params,config);
  }else if (method === "getFile"){
    return getFile(url, params);
  }
}

// 封装get方法
function sendGet(url, params) {
  return new Promise((resolve, reject) => {
    params = QS.stringify(params, {
      // arrayFormat: 'repeat',
      // arrayFormat: "indices",
      // allowDots: true,
    });
    if (params != "") {
      url = `${url}?${params}`;
    }
     
    axios
      .get(url,params, {
        headers: {
          "siteId": params.siteId,
          "code": params.code,
        },
      })
      .then((respnse) => {
        let res = respnse.data;
        if (res.isSuccess && res.code == 200) {
          resolve(res);
        } else if (res.isSuccess && (res.code == 203||res.code == 204)) {
          resolve(res);
        }else if (res.code == 500) {
          ElMessage({
            message: res.body.length > 100 ? "服务器内部错误" : res.body,
            type: "error",
            grouping: true,
          });
          console.log("err", res.body);
          reject(res);
        } else if (res.code == 201) {
          reject(res);
        }else {
          console.log("post !200 res", res);
          ElMessage({
            message: res.body || "Send Get Error",
            type: "error",
            grouping: true,
          });
          reject(res);
        }
      }).then(()=>{
        
      })
      .catch((err) => {
        console.log("get err", err);
        reject(err);
      });
  });
}

// 封装form方法
function sendForm(url, params = "") {
  return new Promise((resolve, reject) => {
    // params = QS.stringify(params);
    params = QS.stringify(params, {
      // arrayFormat: 'repeat',
      arrayFormat: "indices",
      allowDots: false,
    });
    // params = JSON.stringify(params); //springboot
    axios
      .post(url, params, {
        headers: {
          "siteId": params.siteId,
          "code": params.code,
          "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((respnse) => {
        let res = respnse.data;
        if (res.isSuccess && res.code == 200) {
          resolve(res);
        } else if (res.code == 500) {
          ElMessage({
            message: res.body.length > 100 ? "服务器内部错误" : res.body,
            type: "error",
            grouping: true,
          });
          console.log("err", res.body);
          reject(res);
        } else {
          ElMessage({
            message: res.body || "Send Form Code Error",
            type: "error",
            grouping: true,
          });
          reject(res);
        }
      })
      .catch((err) => {
        console.log("post err", err);
        ElMessage({
          message: err || "Send Form Error",
          type: "error",
          grouping: true,
        });
        reject(err);
      });
  });
}

// 封装post方法
function sendPost(url, params = "") {
  return new Promise((resolve, reject) => {
    params = QS.stringify(params, {
      // arrayFormat: 'repeat',
      arrayFormat: "indices",
      allowDots: true,
    });
    axios
      .post(url, params, {"siteId": params.siteId, "code": params.code})
      .then((respnse) => {
        let res = respnse.data;
        if (res.isSuccess && res.code == 200) {
          resolve(res);
        }else if (res.isSuccess && (res.code == 203||res.code == 204)) {
          resolve(res);
        } else if (res.code == 500) {
          ElMessage({
            message: res.body.length > 100 ? "服务器内部错误" : res.body,
            type: "error",
            grouping: true,
          });
          reject(res);
        } else {
          console.log("000",res)
          ElMessage({
            message: res.body || "Send Post Code Error",
            type: "error",
            grouping: true,
          });
          reject(res);
        }
      })
      .catch((err) => {
        console.log("233",err)
        ElMessage({
          message: err || "Send Post Error",
          type: "error",
          grouping: true,
        });
        // console.log("form err", err);
        reject(err);
      });
  });
}

function getFile(url, params = "") {
  return new Promise((resolve, reject) => {
    params = QS.stringify(params, {
      // arrayFormat: 'repeat',
      arrayFormat: "indices",
      allowDots: true,
    });
    axios
      .post(url, params, {responseType:'blob'})
      .then((respnse) => {
        if (respnse.status == 200) {
          resolve(respnse);
        }else if (respnse.status == 500) {
          ElMessage({
            message: respnse.statusText,
            type: "error",
            grouping: true,
          });
          reject(respnse);
        } else {
          ElMessage({
            message: "Send Post Code Error",
            type: "error",
            grouping: true,
          });
          reject(respnse);
        }
      })
      .catch((err) => {
        ElMessage({
          message: err || "Send Post Error",
          type: "error",
          grouping: true,
        });
        // console.log("form err", err);
        reject(err);
      });
  });
}

// 封装file方法
function sendFile(url, params = "",config) {
  console.log("config",config)
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers: {
             "content-type": "multipart/form-data;charset=UTF-8",
             "siteId": config.siteId, "code": config.code
        },
        onUploadProgress: (progressEvent) => {
             let processStatu = (((progressEvent.loaded / progressEvent.total) * 100) | 0) ;
             var index = processStatu;
             console.log("request-2",index)
             config.pageRef.progressPercent = index;
             if(index>=100){
                 config.pageRef.progressPercent=100
                 config.pageRef.fileElProgress=false
             }
         },
      })
      .then((respnse) => {
        console.log("respnse",respnse)
        let res = respnse.data;
       
        if (res.isSuccess && res.code == 200) {
          resolve(res);
        } else if (res.code == 500) {
          ElMessage({
            message: res.body.length > 100 ? "服务器内部错误" : res.body,
            type: "error",
            grouping: true,
          });
          console.log("err", res.body);
          reject(res);
        } else {
          ElMessage({
            message: res.body || "Send File Error",
            type: "error",
            grouping: true,
          });
          reject(res);
        }
      })
      .catch((err) => {
        // alert('请求失败')
        console.log(err);
        reject(err);
      });
  });
}

// request interceptor
axios.interceptors.request.use(
  (config) => {
    
    var managerToken = localStorage.getItem("managertoken");
    // console.log('loginToken', loginToken);
    if (managerToken) {
      config.headers["managertoken"] = managerToken;
    }
  
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  (response) => {
    //console.log("===== interceptor response", response);
    const res = response.data;
    
    if (res.code === 402) {
      setTimeout(() => {
        router.push("/manager/login");
      }, 1000)
      return response;
    } else if (res.code === 201) {
       
      ElMessage({
        message: res.body || "Send File Error",
        type: "error",
        grouping: true,
      });
      return response;
    } else {
      // 非401的错误
      return response;
    }
  },
  (error) => {
    // console.log("===== interceptor error", error);
    let err = error;
    if (err.code === 406) {
      // alert("未登录");
      // router.push("/manager/login");
    }
    return err;
  }
);

// export default service
